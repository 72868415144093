const theme = () => ({
   app:{
      position:"absolute",
      display:"flex",
      width:"100%",
      height:"100%",
      flexDirection:"column",
      justifyContent:"space-between",
      alignItems:"center",
   },
   marginal:{
      display:"flex",
      justifyContent:"center",
      width:"100%",
      padding:"1rem 0",
      background:"rgba(0,0,0,0.1)",
   },
   content:{
      width:"100%",
      height:"100%",
      display:"flex",
      flexDirection:"column",
      justifyContent:"space-evenly",
      alignItems:"center",
   }
  
  });

  export default theme
