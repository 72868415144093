
const theme = () => ({

    darkTheme:{
        background:"#333",
        color:"#fff",
    },
    lightTheme:{
        background:"#eee",
    }

});
export default theme
