
//App.js preps the browser to contain a web app

import React, { useEffect} from 'react'
import './styles/App.css'
// import Layout from './components/Layout'
import Layout from "./components/Layout"

function App() {
  // const classes = useStyles();
    useEffect(()=>{
        preventIosTouch()
      },[])

    function preventIosTouch(){
        document.addEventListener(
          'gesturestart', (e) => e.preventDefault()
      );
      }


    return (
          <div >
            <Layout/>
          </div>
        
    )
}
export default App