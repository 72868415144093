
//Auth.js checks authentication and routes the user to the appropriate pages

import React from 'react'

function Auth() {
    return (
        <div>
            Auth routing
        </div>
    )
}

export default Auth
